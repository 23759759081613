<template>
  <div class="custom-pagination">
    <div
      class="vs-pagination-content notMargin vs-component--primary space-x-2"
    >
      <div
        class="block m-auto cursor-pointer"
        @click="$emit('input', '')"
        v-if="value && value.length === 1"
      >
        <div class="rounded-md bg-brand bg-opacity-40">
          <XIcon class="h-6 w-6 p-1" aria-hidden="true" />
        </div>
      </div>
      <!--            <button-->
      <!--              v-if="value && value.length === 1"-->
      <!--              @click="$emit('input', '')"-->
      <!--            >-->
      <!--              <i class="bx bx-x"></i>-->
      <!--            </button>-->
      <div class="vs-pagination">
        <button
          :key="item"
          v-for="item in alphabets"
          :class="item === value ? 'active' : ''"
          @click="$emit('input', item)"
          class="vs-pagination__button"
        >
          {{ item }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { XIcon } from "@heroicons/vue/outline";
export default {
  name: "AlphaPagination",
  data() {
    return {
      alphabets: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    };
  },
  components: {
    XIcon,
  },
  props: {
    value: {
      default: "",
      type: [String],
    },
  },
};
</script>

<style lang="scss">
.custom-pagination {
  display: flex;
  .vs-pagination__button.active {
    color: white;
    background-color: rgba(var(--brand), 1);
    box-shadow: 0px 5px 20px 0px rgba(17, 18, 20, 0.3);
  }
}
</style>
