<template>
  <div class="block w-full">
    <span class="text-gray-700">{{ label }}</span>
    <Field
      :disabled="!enabled"
      :name="name"
      :id="name"
      :type="type"
      :label="label"
      @input="handleChange"
      @blur="handleBlur"
      class="
        mt-1
        block
        w-full
        rounded-md
        bg-gray-100
        border-transparent
        focus:border-gray-500 focus:bg-white focus:ring-0
      "
      :class="{ 'border-red-500': !!errorMessage, success: meta.valid }"
      :placeholder="placeholder"
    />
    {{}}
    <p class="text-red-500" v-show="errorMessage || meta.valid">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { useField, Field } from "vee-validate";

export default {
  name: "LInputField",
  props: {
    type: {
      type: String,
      default: "text",
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  components: { Field },
};
</script>
